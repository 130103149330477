import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import config, { device } from '../styles/config'

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 8rem;
  max-width: ${ config.pageWidth };
  padding-left: ${ config.pagePadding };
  padding-right: ${ config.pagePadding };

  @media ${ device.sm } {
    padding-left: ${ config.pagePaddingMobile };
    padding-right: ${ config.pagePaddingMobile };
  }

  font-family: 'GT America';
  font-size: 1.61rem;
  line-height: 1.413;
  letter-spacing: 0.62px;
  font-weight: 300;

  p {
    padding-bottom: 1rem;
  }

  a {
    text-decoration: underline;
  }
`

const AboutText = styled.div`
  margin-bottom: 4em;
`

const PrivacyPolicyLink = styled(Link)`
  display: block;
  margin-top: 2rem;
  font-family: 'GT America', sans-serif;
  font-size: 18px;
  text-decoration: underline;
  font-weight: normal;
  margin-bottom: 5rem;
  color: var(--color-text);
`

const ContactPage = ({ data: { datoCmsContact: { text, aboutText } } }) => {
  return (
    <Container>
      <AboutText dangerouslySetInnerHTML={{ __html: aboutText }}/>

      <div dangerouslySetInnerHTML={{ __html: text }}/>
      
      <PrivacyPolicyLink to="/privacy-policy">privacy policy</PrivacyPolicyLink>
    </Container>
  )
}

export const query = graphql`
  query ContactPageQuery {
    datoCmsContact {
      text
      aboutText
    }
  }
`

export default ContactPage
